<template>
  <b-container fluid>
    <ValidationObserver ref="productTypeObserver" v-slot="{ invalid }">
      <form>
        <b-form-group class="col-md-12" :label="$t('Name')" label-for="name">
          <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
            <b-form-input :class="(errors.length > 0 || nameIsExists == true ? ' is-invalid' : '')" v-model="model.name" type="text">
            </b-form-input>
            <b-form-invalid-feedback>
              {{ nameIsExists == true ? $t('ThisServiceGroupNameAlreadyExists') : $t('ValidationMessage.CannotBeEmpty') }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>

        <b-form-group class="col-md-12" :label="$t('TaxRate')" label-for="vatPercentage">
          <b-form-input v-model="model.vatPercentage " type="number" :placeholder="$t('Example18')" @keypress="$root.isNumber"></b-form-input>
        </b-form-group>

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitNewProductType"></modal-footer-button>

      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import productServices from '../../../services/product'

  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  export default {
    name: 'ProductType',
    components: {
      ModalFooterButton,
    },
    props: {
      productTypeId: String
    },
    data() {
      return {
        model: {
          id: '',
          name: '',
          vatPercentage: '',
        },
        nameIsExists: false,
        isSubmitting: false
      }
    },
    methods: {
      async submitNewProductType() {
        const isValid = await this.$refs.productTypeObserver.validate();
        if (!isValid || this.nameIsExists == true) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }

        this.isSubmitting = true;

        productServices.submitNewProductType(this.model).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t("Success"));
            refreshSmartDuoData();
          }
          else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      getProductTypeNameExists() {
        productServices.getProductTypeNameExists(this.model.id, this.model.name)
          .then((response) => {
            this.nameIsExists = response;
          })
      },
      getProductTypeForEdit() {
        productServices.getProductTypeForEdit(this.model.id).then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
    },
    watch: {
      'model.name': function (name) {
        if (name && name.length > 2)
          this.getProductTypeNameExists();
      },
    },
    mounted() {
      if (this.productTypeId && this.productTypeId.length > 0) {
        this.model.id = this.productTypeId;
        this.getProductTypeForEdit();
      }
    }
  }
</script>
